<template>

  <section class="text-center main-footer">

    <p style="font-size: var(--font-largest);font-weight: bold;margin-top: .5em; margin-bottom: 0.4em;">Tapa<span style="color: #fbeb34;">BETS</span></p>


    <p style="margin: 0;">
      <a href="tel:0722000001">
        <button class="btn btn-sm btn-round">
          <span><img style="height: 1.5em; font-weight: bold;" src="	/assets/icons/phone.svg"></span> <span class="white-txt" style="font-size: 13.6px; font-weight: bold">0100 000 000</span>
        </button>
      </a>
    </p>

    <div class="footer-social mb-3" style="margin-bottom:  0.5em !important;">
      <a href="https://web.facebook.com/tapabet" target="_blank" class="btn btn-fab btn-round btn-facebook">
        <img class="social-btn" src="	/assets/icons/facebook.svg">
      </a>

      <a href="https://twitter.com/tapabet" target="_blank" class="btn btn-fab btn-round btn-twitter">
        <img class="social-btn" src="	/assets/icons/twitter.svg">
      </a>

      <a href="https://www.instagram.com/tapabet/" target="_blank" class="btn btn-fab btn-round btn-instagram">
        <img class="social-btn" src="	/assets/icons/instagram.svg">
      </a>
    </div>

    <p>
      <img style=" height: 29px;margin-top: .3em;" src="	/assets/icons/age-limit.svg">
    </p>

    <ul class="footer-menu">
      <li class="list-item"><router-link style="font-size: 13.6px; text-align: center !important;" to="/howto" class="white-txt">Help</router-link></li>
      <li class="list-item"><router-link style="font-size: 13.6px; text-align: center !important;" class="white-txt" to="/faq">FAQS</router-link></li>
      <li class="list-item"><router-link style="font-size: 13.6px; text-align: center !important;" to="/terms" class="white-txt">Terms</router-link></li>
      <li class="list-item"><router-link style="font-size: 13.6px; text-align: center !important;" to="/responsible-gaming" class="white-txt">Responsible Gaming</router-link></li>
    </ul>

    <div>
      <p class="legal-text" style="font-size: var(--font-medium);line-height: 1.2em;">
<!--        Stonewood Enterprises trading as tapabet is Licenced by the Betting Control & Licensing Board of Kenya. License 0000352.-->
      </p>
    </div>

  </section>

</template>

<script>

export default {
  name: "BottomFooter",
}

</script>