<template>

  <div class="body1">

    <div>
      <div class="bd-callout bd-callout-default">
        <p class="left">
          <button class="btn btn-link btn-sm black-txt" @click="goBack">
            <arrow-left-icon></arrow-left-icon>
          </button>
          <span class="right">
              RESPONSIBLE GAMBLING
          </span>
        </p>
      </div>
    </div>
    
    <pre></pre>
    
    <div>
      <div class="bd-callout bd-callout-default list">
        <h3>Responsible Gambling</h3>
        <p>
          tapabet is committed to Responsible Gambling. We promote gambling as an enjoyable leisure activity and we believe that gambling can only remain this way if you stay in control and gamble responsibly. However, we know that for some people gambling can stop being a harmless leisure activity and become a problem.
        </p>
        <p>
          To assist you we offer a range of advice and account options to help you manage your gambling. These give you control of both the time and money you spend gambling.
        </p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">Advice on Staying in Control</p>
        <p>We believe that gambling should always be an enjoyable leisure activity. Remembering these simple tips can help make sure your gambling doesn’t become a problem.</p>
        <ul>
          <li>Gambling should be entertaining and not seen as a way of making money.</li>
          <li>Bet sensibly and never chase your losses.</li>
          <li>Only gamble what you can afford to lose. Use Deposit Limits to manage the amount of money you spend.</li>
          <li>Monitor the amount of time you spend playing. Set up Reality Checks to remind you how long you have been logged into your account.</li>
          <li>Balance gambling with other activities. If gambling is your only form of entertainment think about whether you are still having fun.</li>
          <li>Take regular breaks from gambling. Gambling continuously can cause you to lose track of time and perspective.</li>
          <li>Don’t gamble when under the influence of alcohol or when you’re upset or depressed.</li>
          <li>Think about how much money you spend gambling. You can track your activity in your Online Account History.</li>
          <li>If you need to talk to someone about problem gambling then Contact our customer service team or a problem gambling support service.</li>
        </ul>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">Deposit Limits</p>
        <p>We encourage you to set a deposit limit to manage the amount of money that you can deposit into your account. Limits can be set for a 24 hour, 7 day or 30 day period and cannot be overridden.</p>
        <p>You can decrease your deposit limits at any time and this will be applied immediately.</p>
        <p>Before increasing your deposit limits carefully consider if you can afford to do so. Never decide to increase your limit because you have lost money and think that you will win it back by gambling more. If you wish to increase a limit you will need to wait 24 hours before we action your request and then return to our site to confirm that you still want to increase it.</p>
        <p>You can set, amend and confirm your deposit limits in the Responsible Gambling Controls section of Members by choosing Deposit Limits.</p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">Online Account History</p>
        <p>To enable you to keep track of your activity, you can access a history of your transactions, deposits and withdrawals in our My Account area and your balance is always visible in the bottom right corner of the page when logged in to tapabet.</p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">Time-out</p>
        <p>If you want to take a short break from betting and gaming with us, you can do so by taking a Time-Out for a period of 24 hours, 48 hours, 7 days or 30 days.</p>
        <p>Once you begin your Time-Out, you will not be able to use your account for betting and gaming, although you will still be able to login to withdraw any remaining balance. It will not be possible to reactivate your account until your chosen period has ended. To take a Time-Out please visit the Responsible Gambling Controls section of Members by choosing Time-Out.</p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">Self-Exclusion</p>
        <p>If you feel you are at risk of developing a gambling problem or believe you currently have a gambling problem, please consider Self-Exclusion. If you want to stop playing for any other reason, please consider taking a Time-Out or using Account Closure.</p>
        <p>Self-Exclusion allows you to close your account for a specified period of 6 months, 1 year, 2 years or 5 years. This prevents you gambling with tapabet for your selected period of time. During a period of self-exclusion you will not be able to use your account for betting and gaming, although you will still be able to login to withdraw any remaining balance. It will not be possible to re-open your account for any reason, and tapabet will do all it can to detect and close any new accounts you may open.</p>
        <p>You also have the option of selecting which areas of the site you would like to limit your access to. For example, you could limit your access to our Sports, Tapa League, Casino or any other products.</p>
        <p>Should you opt to self-exclude from tapabet, we strongly recommend that you seek exclusion from all other gambling operators you have an account with. Whilst we will remove you from our marketing databases, we also suggest that you remove tapabet from your notifications and delete/uninstall all tapabet apps, downloads and social media links. You may also wish to consider installing software that blocks access to gambling websites.</p>
        <p>We recommend that you seek support from a problem gambling support service to help you deal with your problem.</p>
        <p>Our customer service staff receive dedicated training on dealing with problem gambling issues and are available to listen and to support you in keeping control. If you would like to talk to someone here about problem gambling, please Contact Us.</p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">Protecting Minors</p>
        <p>It is against the law for those under the age of 18 to gamble and tapabet takes its responsibility to prevent underage gambling very seriously. We carry out age verification checks on all customers and ask for documentary proof of age where checks are inconclusive.</p>
        <p>The accounts of anyone under the age of 18 found to be gambling with tapabet will be closed and any winnings will be forfeited.</p>
        <p>If you suspect that someone under the age of 18 is gambling with tapabet please Contact Us to report this to government authorities.</p>


        <p class="bold">Website Blocking Software</p>
        <p>There are a number of third party applications that you can use to monitor or restrict access to the internet:</p>
        <ul>
          <li>Net Nanny (www.netnanny.com): General blocking software that protects children from inappropriate web content. Also available on iOS and Android devices. </li>
          <li>CYBERsitter (www.cybersitter.com): Filtering software allowing parents to choose their own sites to block.</li>
          <li>GamBlock (www.gamblock.com): Specific blocking software for gambling websites. Also available for Android devices.</li>
          <li>Betfilter (www.betfilter.com): Specific blocking software for gambling websites. Also available for Android devices. You may also want to familiarise yourself with the built-in parental controls on your mobile, tablet, PC or TV.</li>
        </ul>
        <p>There are also a number of independent problem gambling support services who offer a range of contact methods together with free and confidential advice.</p>
        <p>If you are concerned about your gambling you can click here to complete our problem gambling self-assessment, this will help you consider whether you may have a problem.</p>
        <p>If you are concerned about the gambling behaviour of a friend or family member, you can find more information here.</p>

      </div>
    </div>

    <BottomFooter></BottomFooter>

    <bottom-navigation active-bets="0" page="login"></bottom-navigation>

  </div>

</template>

<script>
import BottomFooter from "@/components/BottomFooter";
import BottomNavigation from "@/components/BottomNavigation";

export default {
  name: 'Responsible',
  components: {
    BottomFooter,
    BottomNavigation
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","responsible");
    this.reloadProfile();

  }

}
</script>